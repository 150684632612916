<template>
  <div class="home-wrapper">
    <template v-if="$canAction(['Report_View'])">
      <Row>
        <Col :span="24">
          <DatePicker
            v-model="filterDate"
            placeholder="请选择日期范围"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            style="width: 500px"
            @on-ok="getIndexData"
          ></DatePicker>
          <Button
            type="primary"
            style="margin-left: 20px"
            size="mini"
            @click="getIndexData"
          >
            搜索
          </Button>
        </Col>
      </Row>
      <Row style="margin-top: 20px" :gutter="20">
        <Col :span="6">
          <div class="card bg1">
            <div class="number">
              <span style="margin-right: 10px">¥</span>
              {{ homeData.TotalPayAmount || 0 }}
            </div>
            <div class="title">
              <span class="title-text">总支付金额</span>
            </div>
          </div>
        </Col>
        <Col :span="6">
          <div class="card bg3">
            <div class="number">
              <span style="margin-right: 10px">¥</span
              >{{ homeData.TotalRefundAmount || 0 }}
            </div>
            <div class="title">
              <span class="title-text">总退款金额</span>
            </div>
          </div>
        </Col>
        <Col :span="6">
          <div class="card bg2">
            <div class="number">{{ homeData.NewOrderCount || 0 }}</div>
            <div class="title">
              <span class="title-text">总支付订单数</span>
            </div>
          </div>
        </Col>
        <Col :span="6">
          <div class="card bg1">
            <div class="number">
              {{ homeData.OrderProductNum || 0 }}
            </div>
            <div class="title">
              <span class="title-text">总商品销量</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row style="margin-top: 20px" :gutter="20">
        <Col :span="6">
          <div class="panel">
            <div class="panel-title">待办事项</div>
            <div class="panel-content">
              <div class="card left">
                <div class="title">待支付订单</div>
                <div class="number">
                  {{ homeData.WaitPayCount || 0 }}<span>个</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">待发货订单</div>
                <div class="number">
                  {{ homeData.WaitShippingCount || 0 }}<span>个</span>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="panel">
            <div class="panel-title">会员概况</div>
            <div class="panel-content">
              <div class="card left">
                <div class="title">累计会员数</div>
                <div class="number">
                  {{ memberStat.TotalMember || 0 }}<span>人</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">购买用户</div>
                <div class="number">
                  {{ memberStat.BuyMember || 0 }}<span>人</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">未购买用户</div>
                <div class="number">
                  {{ memberStat.NotBuyMember || 0 }}<span>人</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">客单价</div>
                <div class="number">
                  {{ memberStat.PerMemberAmount || 0 }}<span>元</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">分销商</div>
                <div class="number">
                  {{ bonusStat.TotalDealer }}<span>人</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">待审核</div>
                <div class="number">
                  {{ bonusStat.WaitAuditDealer || 0 }}<span>人</span>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="panel">
            <div class="panel-title">分销统计</div>
            <div class="panel-content">
              <div class="card left">
                <div class="title">累计佣金</div>
                <div class="number">
                  {{ bonusStat.TotalBonus || 0 }}<span>元</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">已提现佣金</div>
                <div class="number">
                  {{ bonusStat.TotalDrawBonus || 0 }}<span>元</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">未提佣金</div>
                <div class="number">
                  {{ bonusStat.WaitDrawBonus || 0 }}<span>元</span>
                </div>
              </div>
              <div class="card left">
                <div class="title">待打款佣金</div>
                <div class="number">
                  {{ bonusStat.WatiAuditBonus || 0 }}<span>元</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col :span="12">
          <div class="panel">
            <div class="panel-title">
              <div style="display: flex; justify-content: space-between">
                <span style="flex: 1">交易概况</span>
                <Select
                  @on-change="changeType"
                  v-model="chartsType"
                  style="width: 120px"
                >
                  <Option :value="0">支付金额</Option>
                  <Option :value="1">订单数</Option>
                </Select>
              </div>
            </div>
            <div class="echart-box">
              <div style="width: 100%; height: 500px" id="echart-wrapper"></div>
            </div>
          </div>
        </Col>
        <Col :span="6">
          <div class="panel">
            <div class="panel-title">近7天排行</div>
            <Tabs name="1">
              <TabPane label="商品排行" name="1">
                <Table
                  :columns="[
                    { title: '排名', key: 'index' },
                    { title: '商品名称', key: 'Name' },
                    { title: '销量', key: 'Sells' },
                  ]"
                  :data="productRank"
                ></Table>
              </TabPane>
              <TabPane label="会员排行" name="2">
                <Table
                  :columns="[
                    { title: '排名', key: 'index' },
                    { title: '会员名称', key: 'MemberName' },
                    { title: '支付金额', key: 'TotalAmount' },
                  ]"
                  :data="memberRank"
                ></Table>
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </template>
  </div>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import echarts from "echarts";

export default {
  data() {
    return {
      charts: null,
      chartsType: 0,
      homeData: {},
      bonusStat: {},
      memberStat: {},
      productRank: [],
      memberRank: [],
      filterDate: [
        dayjs()
          .set("hour", 0)
          .set("minutes", 0)
          .set("seconds", 0)
          .format("YYYY-MM-DD HH:mm:ss"),
        dayjs()
          .set("hour", 23)
          .set("minutes", 59)
          .set("seconds", 59)
          .format("YYYY-MM-DD HH:mm:ss"),
      ],
    };
  },
  async mounted() {
    if (this.$canAction(["Report_View"])) {
      this.getIndexData();
      const res2 = await api.GetBonusStat();
      this.bonusStat = res2.Data;
      const res3 = await api.GetMemberStat();
      this.memberStat = res3.Data;
      const res4 = await api.getProductSellsRank({
        endDate: `${dayjs().format("YYYY-MM-DD")} 23:59:59`,
        startDate: `${dayjs()
          .subtract(7, "day")
          .format("YYYY-MM-DD")} 00:00:00`,
      });
      this.productRank = res4.Data.map((item, index) => ({
        index: index + 1,
        ...item,
      }));
      const res5 = await api.getMemberShoppingRank({
        endDate: `${dayjs().format("YYYY-MM-DD")} 23:59:59`,
        startDate: `${dayjs()
          .subtract(7, "day")
          .format("YYYY-MM-DD")} 00:00:00`,
      });
      this.memberRank = res5.Data.map((item, index) => ({
        index: index + 1,
        ...item,
      }));
    }
  },
  methods: {
    async getIndexData() {
      const res = await api.GetIndexData({
        beginDate: dayjs(this.filterDate[0]).format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs(this.filterDate[1]).format("YYYY-MM-DD HH:mm:ss"),
      });
      this.homeData = res.Data.TopData;
      this.HistoryOrder = res.Data.HistoryOrder;
      const option = {
        xAxis: {
          type: "category",
          data: res.Data.HistoryOrder.List.map(
            (item) => `${item.Month}-${item.Day}`
          ),
        },
        yAxis: {
          type: "value",
        },
        legend: {
          data: ["支付金额"],
        },
        series: [
          {
            data: res.Data.HistoryOrder.List.map((item) => item.Amount),
            type: "line",
            name: "支付金额",
            smooth: true,
          },
        ],
      };

      this.charts = echarts.init(document.getElementById("echart-wrapper"));
      this.charts.setOption(option);
    },
    changeType() {
      const option = {
        xAxis: {
          type: "category",
          data: this.HistoryOrder.List.map(
            (item) => `${item.Month}-${item.Day}`
          ),
        },
        yAxis: {
          type: "value",
        },
        legend: {
          data: [["支付金额", "订单数"][this.chartsType]],
        },
        series: [
          {
            data: this.HistoryOrder.List.map(
              (item) => item[this.chartsType === 0 ? "Amount" : "Count"]
            ),
            type: "line",
            name: ["支付金额", "订单数"][this.chartsType],
            smooth: true,
          },
        ],
      };
      this.charts.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.home-wrapper {
  height: 100%;
  width: 100%;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    &.left {
      align-items: flex-start;
    }
    &.bg1 {
      background: linear-gradient(
        rgb(242, 249, 254) 0%,
        rgb(230, 244, 254) 100%
      );
    }
    &.bg2 {
      background: linear-gradient(
        rgb(245, 254, 242) 0%,
        rgb(230, 254, 238) 100%
      );
    }
    &.bg3 {
      background: linear-gradient(
        rgb(247, 247, 255) 0%,
        rgb(236, 236, 255) 100%
      );
    }

    .number {
      font-size: 26px;
      white-space: nowrap;
      color: #1d2129;
      font-weight: 500;
      span {
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
      color: #4e5969;
      &-text {
        margin-left: 10px;
      }
    }
  }
  .panel {
    padding: 20px;
    border: 1px solid #f2f2f2;
    &-title {
      font-size: 16px;
      color: #4e5969;
      font-weight: 700;
    }
    &-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      .card {
        padding: 30px 0;
      }
    }
  }
}
</style>
